import { toast } from 'react-toastify';

/**
 * Copies text to clipboard.
 *
 * NOTE: This function uses react-toastify to inform the user that
 * text is copied. react-toastify requires ToastContainer
 * component to be rendered on the page where toast
 * should be shown.
 *
 * @param {String} text Some text to copy
 */
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    toast('Copied to the clipboard');
  });
};
