import * as React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { IconArrowNarrowRight, IconArrowNarrowLeft } from '@tabler/icons';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../components/header';
import Container from '../components/container';
import Box from '../components/box';
import SEO from '../components/seo';
import mdxComponents from '../components/mdx';
import PostMeta from '../components/post-meta';
import Link from '../components/link';
import Footer from '../components/footer';

const BlogPost = ({ data, pageContext }) => {
  const { frontmatter, body, timeToRead } = data.mdx;
  const { nextPost, previousPost } = pageContext;

  return (
    <>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        publishedAt={frontmatter.date}
        image={frontmatter?.ogimage?.childImageSharp?.original?.src ?? ''}
        isArticle
        canonical={frontmatter?.canonical}
      />

      <Header
        title={frontmatter.title}
        postMetaRender={() => (
          <PostMeta frontmatter={frontmatter} readTime={timeToRead} />
        )}
        isPostPage
      />

      <MDXProvider components={mdxComponents}>
        <main className="blog-post-page">
          <Container>
            <Box>
              <article className="blog-post--details">
                <MDXRenderer>{body}</MDXRenderer>
              </article>
            </Box>
          </Container>
        </main>
      </MDXProvider>

      {(previousPost || nextPost) && (
        <section className="md-margin-top">
          <Container className="container read-next--container">
            <Box className="post-links horizontal">
              {previousPost && (
                <Box className="vertical full-width sm-margin-right">
                  <p>
                    <IconArrowNarrowLeft size={20} stroke={2} />
                    <span>Previous</span>
                  </p>
                  <h3>
                    <Link
                      className="link-clear link-hover"
                      to={`/blog/${previousPost.slug}`}
                    >
                      {previousPost.title}
                    </Link>
                  </h3>
                </Box>
              )}
              {nextPost && (
                <Box className="vertical full-width h-end">
                  <p>
                    <span>Next</span>
                    <IconArrowNarrowRight size={20} stroke={2} />
                  </p>
                  <h3 className="text-right">
                    <Link
                      className="link-clear link-hover"
                      to={`/blog/${nextPost.slug}`}
                    >
                      {nextPost.title}
                    </Link>
                  </h3>
                </Box>
              )}
            </Box>
          </Container>
        </section>
      )}

      <Footer />
    </>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostQuery($postId: String!) {
    mdx(id: { eq: $postId }) {
      id
      frontmatter {
        title
        tag
        slug
        date(formatString: "DD.MM.YYYY")
        description
        canonical
        ogimage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      body
      timeToRead
    }
  }
`;
