import * as React from 'react';
import { IconCalendarEvent, IconClock, IconLink } from '@tabler/icons';
import { useStaticQuery, graphql } from 'gatsby';

import Box from './box';
import { addTrailingSlash, copyToClipboard } from '../utils';

const PostMeta = ({ frontmatter, readTime }) => {
  const { date, slug } = frontmatter;

  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            paths {
              blog
            }
          }
        }
      }
    `
  );

  return (
    <Box className="horizontal post-meta-wrapper">
      <div className="post-meta">
        <IconCalendarEvent size={20} stroke={2} />
        <span>{date}</span>
      </div>

      <div className="post-meta">
        <IconClock size={20} stroke={2} />
        <span>{`${readTime} min read`}</span>
      </div>

      <div
        style={{ cursor: 'pointer' }}
        className="post-meta"
        onClick={() =>
          copyToClipboard(
            addTrailingSlash(
              `${siteMetadata.siteUrl}${siteMetadata.paths.blog}${slug}`
            )
          )
        }
      >
        <IconLink size={20} stroke={2} />
        <span>Copy link</span>
      </div>
    </Box>
  );
};

export default PostMeta;
