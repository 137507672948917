import * as React from 'react';
import PropTypes from 'prop-types';
import {
  IconInfoCircle,
  IconAlertTriangle,
  IconCircleCheck,
  IconCircleX,
} from '@tabler/icons';

import Box from './box';

const ALERT_LEVELS = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

const Alert = ({ level = '', title = '', children }) => {
  if (!ALERT_LEVELS[level.toUpperCase()]) {
    return null;
  }

  return (
    <Box className={`alert ${level} horizontal`}>
      <Box className="alert-icon">
        {level === ALERT_LEVELS.SUCCESS ? (
          <IconCircleCheck size={24} stroke={2} />
        ) : null}
        {level === ALERT_LEVELS.INFO ? (
          <IconInfoCircle size={24} stroke={2} />
        ) : null}
        {level === ALERT_LEVELS.WARNING ? (
          <IconAlertTriangle size={24} stroke={2} />
        ) : null}
        {level === ALERT_LEVELS.ERROR ? (
          <IconCircleX size={24} stroke={2} />
        ) : null}
      </Box>

      <Box className="vertical v-center">
        {title ? <p className="alert-title ">{title}</p> : null}
        {children}
      </Box>
    </Box>
  );
};

Alert.propTypes = {
  level: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.node,
};

export default Alert;
