import * as React from 'react';
import theme from 'prism-react-renderer/themes/github';
import Highlight, { defaultProps } from 'prism-react-renderer';

import { copyToClipboard } from '../../utils/index';

const Code = ({ children }) => {
  const code = children.props.children.trim();
  const language = children.props.className.split('-')[1] || '';
  const showLineNumber = children.props.showLineNumber || false;
  const filePath = children.props.filePath || '';

  return (
    <Highlight {...defaultProps} code={code} language={language} theme={theme}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <div className="mdx-code-wrapper">
          {!!filePath && (
            <div className="mdx-code-filename">
              <span>{filePath}</span>
            </div>
          )}
          <div
            className="mdx-code-block"
            title="Click to copy"
            onClick={() => copyToClipboard(code)}
          >
            <pre className={className} style={{ ...style }}>
              {tokens.map((line, i) => (
                <div
                  key={i}
                  {...getLineProps({
                    line,
                    key: i,
                  })}
                >
                  {showLineNumber && (
                    <span className="line-number">{i + 1}</span>
                  )}
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
          </div>
        </div>
      )}
    </Highlight>
  );
};

export default Code;
