import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Container from './container';
import Box from './box';
import Link from './link';

const Header = ({
  title,
  subtitle,
  description,
  postMetaRender,
  isPostPage,
}) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            paths {
              blog
            }
          }
        }
      }
    `
  );

  return (
    <header>
      <div className="overlay"></div>
      <Container className="v-center content-wrapper">
        {isPostPage && (
          <nav>
            <Box className="horizontal">
              <Link to={`${siteMetadata.paths.blog}`}>All posts</Link>
            </Box>
          </nav>
        )}

        <Box className="horizontal">
          <Box className="vertical intro">
            {!isPostPage && !!subtitle && (
              <p className="subtitle">{subtitle}</p>
            )}
            <h1>{title}</h1>
            {!!description && <p>{description}</p>}
          </Box>
        </Box>
        {postMetaRender()}
      </Container>
    </header>
  );
};

Header.defaultProps = {
  subtitle: '',
  description: '',
  postMetaRender: () => null,
  isPostPage: false,
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  cssHeight: PropTypes.string,
  description: PropTypes.string,
  postMetaRender: PropTypes.func,
  isPostPage: PropTypes.bool,
};

export default Header;
