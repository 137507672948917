import * as React from 'react';

import Code from './code';
import Link from '../link';
import Alert from '../alert';

const mdxComponents = {
  wrapper: ({ children }) => children,
  pre: (props) => <Code {...props} />,
  a: (props) => {
    const { href = '', ...restProps } = props;
    return <Link to={href} isNative className="" {...restProps} />;
  },
  alert: (props) => <Alert {...props} />,
};

export default mdxComponents;
